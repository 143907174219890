<template>
  <div class="content-container">
    <a-table
      :rowKey="(e, index) => index"
      :columns="columns"
      :data-source="markups"
      :pagination="{ defaultPageSize: 10, hideOnSinglePage: true }"
      :scroll="{ x: 2000 }"
      :loading="loading"
    >
      <template slot="commissionByCatalog" slot-scope="id">
        {{ id }}
      </template>
    </a-table>

    <div class="payment-group-actions">
      <a-button
        type="default"
        style="margin-top: 20px"
        @click="showPayGroupAdd"
      >
        Добавить группу опта
      </a-button>

      <a-button type="default" style="margin-top: 20px" @click="showPayEdit">
        Редактировать группу опта
      </a-button>
    </div>

    <!-- Group add form -->
    <a-modal
      :visible="visibleGroupAddForm"
      title="Новая группа опта"
      @ok="submitPayGroupAdd"
      @cancel="hidePayGroupAdd"
    >
      <template slot="footer">
        <a-button key="back" @click="hidePayGroupAdd">Отмена</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="submitPayGroupAdd"
        >
          Сохранить
        </a-button>
      </template>

      <p>
        <label>Имя группы опта</label>
        <a-input
          ref="addFormName"
          v-model="addForm.name"
          placeholder="Введите группу опта"
        />
      </p>
      <p>
        <label>Процент по умолчанию</label>
        <a-input
          ref="addFormDefaultPercent"
          v-model="addForm.defaultPercent"
          @change="onAddFormDefaultPercentChange($event.target.value)"
          placeholder="Введите процент по умолчанию"
          :min="0"
          :max="100"
        />
      </p>

      <a-table
        :size="'small'"
        :rowKey="(e, index) => index"
        :dataSource="addForm.items"
        :columns="addForm.columns"
        :pagination="{ defaultPageSize: 8, hideOnSinglePage: true }"
      >
        <template slot="percent" slot-scope="percent">
          <a-input-number
            :value="percent.value"
            @change="setPricePercent(percent.id, $event)"
            :min="0"
            :max="100"
          />
        </template>
      </a-table>
    </a-modal>

    <!-- Group edit form -->
    <a-modal
      :visible="visibleGroupEditForm"
      title="Редактировать группа опта"
      @ok="submitPayGroupEdit"
      @cancel="hidePayEdit"
    >
      <template slot="footer">
        <template v-if="editForm.items.length > 0">
          <a-popconfirm
            title="Уверены, что хотите удалить?"
            ok-text="Удалить"
            cancel-text="Отмена"
            placement="topLeft"
            @confirm="event => onPayGroupDel()"
          >
            <a-button style="float: left" type="danger" key="del">
              Удалить
            </a-button>
          </a-popconfirm>
        </template>

        <a-button key="back" @click="hidePayEdit">Отмена</a-button>

        <template v-if="editForm.items.length > 0">
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="submitPayGroupEdit"
          >
            Сохранить
          </a-button>
        </template>
      </template>

      <p>
        <label>Группа опта</label>

        <a-select
          show-search
          option-filter-prop="children"
          default-value=""
          :value="editForm.id"
          style="width: 100%"
          @change="onEditFormGroupChange"
        >
          <a-select-option value="">Не выбрано</a-select-option>
          <a-select-option
            :key="group.id"
            v-for="group in paymentGroupsMatrix"
            :value="group.id"
          >
            {{ group.name }}
          </a-select-option>
        </a-select>
      </p>

      <template v-if="editForm.items.length > 0">
        <p>
          <label>Имя группа опта</label>
          <a-input
            ref="editFormName"
            :value="editForm.name"
            @change="onEditFormNameChange($event.target.value)"
            placeholder="Введите группу опта"
          />
        </p>

        <p>
          <label>Процент по умолчанию</label>
          <a-input
            :value="editForm.defaultPercent"
            ref="editFormDefaultPercent"
            @change="onEditFormDefaultPercentChange($event.target.value)"
            placeholder="Введите процент по умолчанию"
            :min="0"
            :max="100"
          />
        </p>

        <a-table
          :size="'small'"
          :rowKey="(e, index) => index"
          :dataSource="editForm.items"
          :columns="editForm.columns"
          :pagination="{ defaultPageSize: 8, hideOnSinglePage: true }"
        >
          <template slot="percent" slot-scope="percent">
            <a-input-number
              :value="percent.value"
              @change="setPricePercent(percent.id, $event)"
              :min="0"
              :max="100"
            />
          </template>
        </a-table>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

const addPayForm = function () {
  return {
    name: "",
    defaultPercent: 20,
    items: [],
    columns: [
      {
        title: "Прайс",
        dataIndex: "name",
        key: "id",
      },
      {
        title: "Процент",
        dataIndex: "percent",
        scopedSlots: { customRender: "percent" },
      },
    ],
  }
}

const editPayForm = function () {
  return {
    id: "",
    name: "",
    selectedGroup: {},
    defaultPercent: 20,
    items: [],
    columns: [
      {
        title: "Прайс",
        dataIndex: "name",
        key: "id",
      },
      {
        title: "Процент",
        dataIndex: "percent",
        scopedSlots: { customRender: "percent" },
      },
    ],
  }
}

export default {
  name: "Markups",
  data() {
    return {
      markups: [],
      loading: false,
      visibleGroupAddForm: false,
      visibleGroupEditForm: false,
      addForm: addPayForm(),
      editForm: editPayForm(),
    }
  },
  mounted() {
    this.initMarkups()
  },
  computed: {
    ...mapGetters({
      prices: "prices/prices",
      paymentGroups: "payment/paymentGroups",
      paymentGroupsLatest: "payment/paymentGroupsLatest",
      paymentGroupsMatrix: "payment/paymentGroupsMatrix",
    }),
    columns() {
      const commissionsGroup = this.paymentGroupsMatrix.map(group => {
        return {
          title: group.name,
          dataIndex: `catalog_${group.id}`,
          scopedSlots: { customRender: "commissionByCatalog" },
        }
      })

      return [
        {
          title: "Прайс-листы",
          dataIndex: "catalogName",
          fixed: "left",
        },
        ...commissionsGroup,
      ]
    },
  },
  methods: {
    ...mapActions({
      getAvailablePrices: "prices/fetchAvailablePrices",
      getPaymentGroups: "payment/getPaymentGroups",
      createPaymentGroup: "payment/createPaymentGroup",
      updatePaymentGroup: "payment/updatePaymentGroup",
      getPaymentGroupsLatest: "payment/getPaymentGroupsLatest",
      getPaymentGroupsMatrix: "payment/getPaymentGroupsMatrix",
      delPaymentGroup: "payment/delPaymentGroup",
      getCommissions: "prices/getCommissions",
      setCommissions: "prices/setCommissions",
    }),

    // set data
    async initMarkups() {
      this.loading = true
      await this.getAvailablePrices()
      await this.getPaymentGroupsLatest()
      await this.getPaymentGroupsMatrix()
      await this.getPaymentGroups()
      this.setMarkups(this.prices, this.paymentGroupsMatrix)
      this.loading = false
    },

    setMarkups: function (prices, paymentGroupsMatrix) {
      let markups = []
      prices.forEach(function (catalog, catalogIndex) {
        markups[catalogIndex] = {
          catalogName: catalog.name,
          catalogId: catalog.id,
        }

        paymentGroupsMatrix.forEach(function (group) {
          let groupRow = []
          groupRow[`catalog_${group.id}`] = group.catalogs[catalog.id]
            ? group.catalogs[catalog.id] * 100
            : 0
          Object.assign(markups[catalogIndex], groupRow)
        })
      })

      this.markups = markups
    },

    //add form
    async resetPayGroupAddForm() {
      const vm = this
      let percentId = 0
      vm.addForm = addPayForm()
      this.prices.map(price => {
        vm.addForm.items.push({
          percent: { id: percentId, value: vm.addForm.defaultPercent },
          ...price,
        })
        percentId += 1
      })
    },
    showPayGroupAdd() {
      this.visibleGroupAddForm = true
      this.resetPayGroupAddForm()
    },
    hidePayGroupAdd() {
      this.visibleGroupAddForm = false
    },
    setPricePercent(id, value) {
      this.addForm.items.forEach(item => {
        if (item.percent.id === id) {
          item.percent.value = value
        }
      })
    },
    submitPayGroupAdd: async function () {
      const vm = this

      if (!vm.addForm.name) return
      this.hidePayGroupAdd()

      let catalogs = {}
      this.addForm.items.forEach(item => {
        let obj = {}
        obj[item.id] = item.percent.value / 100
        Object.assign(catalogs, obj)
      })

      await this.createPaymentGroup({
        name: vm.addForm.name,
        percent: vm.addForm.defaultPercent / 100,
        ordering: this.paymentGroups.at(-1).ordering + 1,
        catalogs: catalogs,
      })

      this.initMarkups()
    },
    onDelete(event, data) {
      console.log(data)
    },

    async onPayGroupDel() {
      await this.delPaymentGroup({
        payment_group_id: this.editForm.selectedGroup.id,
      })
      this.hidePayEdit()
      this.initMarkups()
    },

    onAddFormDefaultPercentChange(percentInt) {
      this.addForm.items.forEach(item => {
        if (item.percent.value !== percentInt) {
          item.percent.value = percentInt
        }
      })
    },

    //edit form
    showPayEdit() {
      this.visibleGroupEditForm = true
      this.editForm = editPayForm()
    },
    hidePayEdit() {
      this.visibleGroupEditForm = false
    },
    onEditFormGroupChange(data) {
      let vm = this

      vm.editForm.selectedGroup = vm.paymentGroupsMatrix.find(
        item => item.id === data
      )

      vm.editForm.id = vm.editForm.selectedGroup.id
      vm.editForm.name = vm.editForm.selectedGroup.name
      vm.editForm.defaultPercent = vm.editForm.selectedGroup.percent * 100

      let items = []
      vm.prices.forEach(function (price) {
        items.push({
          id: price.id,
          name: price.name,
          percent: {
            value: vm.editForm.selectedGroup.catalogs[price.id]
              ? vm.editForm.selectedGroup.catalogs[price.id] * 100
              : 0,
          },
        })
      })

      this.editForm.items = items
    },

    onEditFormNameChange(nameStr) {
      this.editForm.nameTemp = nameStr
    },

    onEditFormDefaultPercentChange(percentInt) {
      this.editForm.defaultPercentTemp = percentInt
      this.editForm.items.forEach(item => {
        if (item.percent.value !== percentInt) {
          item.percent.value = percentInt
        }
      })
    },

    submitPayGroupEdit: async function () {
      const vm = this

      if (!vm.editForm.name) return
      this.hidePayEdit()

      let catalogs = {}
      this.editForm.items.forEach(item => {
        let obj = {}
        obj[item.id] = item.percent.value / 100
        Object.assign(catalogs, obj)
      })

      await this.updatePaymentGroup({
        payment_group_id: vm.editForm.selectedGroup.id,
        data: {
          name: vm.editForm.nameTemp,
          percent: vm.editForm.percent,
          //ordering: this.paymentGroups.at(-1).ordering + 1,
          catalogs: catalogs,
        },
      })

      this.initMarkups()
    },

    //other
    formatQuery(markups) {
      const query = {}

      query["byGroup"] = []
      query["byCatalog"] = []

      markups.forEach(markup => {
        const addGroupByType = type => {
          Object.keys(markup).forEach(key => {
            if (this.paymentGroups.some(e => e.id == key)) {
              query[type].push(markup[key])
            }
          })
        }

        if (markup.type === "group") {
          addGroupByType("byGroup")
        } else {
          addGroupByType("byCatalog")
        }
      })

      const toFixedValues = values => {
        return values.map(item => ({
          ...item,
          value: item.value === null ? "0" : item.value.toFixed(1),
        }))
      }

      query["byCatalog"] = toFixedValues(query["byCatalog"])
      query["byGroup"] = toFixedValues(query["byGroup"])

      return query
    },
    async submit() {
      const query = this.formatQuery(this.markups)

      const response = await this.setCommissions(query)

      if (!response) return

      this.initMarkups()
    },
  },
}
</script>

<style lang="scss" scoped>
.selects {
  margin-left: 0;
  width: 400px;
}

.payment-group-actions {
  .ant-btn {
    margin-right: 8px;
    margin-bottom: 12px;
  }
}
</style>
